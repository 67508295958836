import { InfoWindow } from "@react-google-maps/api";

import Outage from "@svgs/Outage";
import Planned from "@svgs/Planned";
import Restored from "@svgs/Restored";

import { mapValues } from "@config";

import { WindowTitle, WindowWrapper } from "./styles";

interface WindowProps {
  outage: any;
  onClose: Function;
  position: any;
}

const Window = ({ outage, position, onClose }: WindowProps) => {
  let color = mapValues.outage_color;
  const renderIcon = () => {
    switch (outage.status.name) {
      case "Outage":
        color = mapValues.outage_color;
        return <Outage />;
      case "Restored":
        color = mapValues.restore_color;
        return <Restored />;
      case "Planned":
        color = mapValues.planned_color;
        return <Planned />;
      default:
        return null;
    }
  };

  return (
    <>
      <InfoWindow position={position} onCloseClick={() => onClose()}>
        <WindowWrapper>
          <WindowTitle color={color}>
            {renderIcon()}
            <span>{outage.status.name}</span>
          </WindowTitle>
          <p>{outage.message}</p>
        </WindowWrapper>
      </InfoWindow>
    </>
  );
};

export default Window;
