import React from "react";
import { useField, useFormikContext } from "formik";

import { FormGroup, FormInput, Feedback } from "@theme/form";

interface FileFieldProps {
  label: string;
  name: string;
  required?: boolean;
}

const File = ({ label, ...props }: FileFieldProps) => {
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(props);

  return (
    <FormGroup>
      <label htmlFor={props.name}>{label}</label>
      <FormInput
        type="file"
        {...props}
        {...field}
        value={field.value.name}
        disabled={isSubmitting}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const reader = new FileReader();

          if (event.currentTarget.files && event.currentTarget.files.length) {
            const file = event.currentTarget.files[0];
            reader.readAsDataURL(file);
            reader.onload = (event: ProgressEvent<FileReader>) => {
              setFieldValue("file", event.target?.result as string);
            };
          }
        }}
      />
      {meta.touched && meta.error ? <Feedback>{meta.error}</Feedback> : null}
    </FormGroup>
  );
};

export default File;
