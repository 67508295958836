import styled from "@emotion/styled";
import { darken, lighten, readableColor } from "polished";

type ButtonProps = {
  bg: string;
  theme?: any;
};

const Button = styled.button`
  ${(props: ButtonProps) => `
		background: linear-gradient(
			to top,
			${props.theme.colors[props.bg]},
			${lighten(0.05, props.theme.colors[props.bg])});
			border-radius: ${props.theme.radii.default};
		color: ${readableColor(props.theme.colors[props.bg])};
		font-size: 1rem;


		&:hover {
			background: ${darken(0.1, props.theme.colors[props.bg])};
			color: ${readableColor(`${darken(0.1, props.theme.colors[props.bg])}`)};
			cursor: pointer;
		}
  `}

  border: none;
  padding: 0.5rem 0.75rem;

  & > svg {
    height: 1rem;
    width: auto;
  }
`;

export default Button;
