import styled from "@emotion/styled";

export const ModalMainDiv = styled.div`
    position:fixed;
    //  opacity:"0.5";
    inset:0;
    background-color:rgba(0,0,0,0.7);
    z-index:9999999;
    display: flex;
    align-items: center
`

export const ModalDiv = styled.div`
    // position: fixed;
    inset: 50%;
    margin: 0 auto;
    margin-top: 50%
    height: max-content;
    min-height: 200px;
    background-color: white;
    width: 600px;
    transform: translate(-50%; -50%);
    border: 5px solid #C8C8C8;
    border-radius: 15px;
    text-align: center;
    color:black;
    z-index:9999999;
    max-width:70%;
`

export const Image = styled.img`
    position:relative;
    cursor:pointer;
    float:right;
    margin:-18px
`

export const ModalData = styled.div`
    color: white ;
    border-radius: 20px  
`

export const ModalTitle = styled.div`
    background-color:#196194;
    text-transform:uppercase;
    font-size:30px;
    color:white;
    padding:10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`

export const ModalMessageContent = styled.p`
    color:black;
    text-align:center;
    padding: 0px 10px
`