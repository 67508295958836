import React from "react";

import { Wrapper, Label, Indicator } from "./styles";

const Legend = () => (
  <Wrapper>
    <Label>Status:</Label>
    <Indicator bg="outage">Outage</Indicator>
    <Indicator bg="primary">Restored</Indicator>
    <Indicator bg="secondary">Planned</Indicator>
  </Wrapper>
);

export default Legend;
