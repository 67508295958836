import React from "react";

import { Wrapper } from "./styles";

const Footer = () => (
  <Wrapper>
    <p>
      &copy;2020, Halton Hills Hydro
    </p>
    <p>
      <strong>Last Updated: {new Date(Date.now()).toString()}</strong>
    </p>
  </Wrapper>
);

export default Footer;
