import React, { memo } from "react";
import { Polygon, Circle } from "@react-google-maps/api";

interface OutageProps {
  outage: any;
  onClick: Function;
}

import { mapValues, polyOptions } from "@config";

const Outage = ({ outage, onClick }: OutageProps) => {
  const opts = { ...polyOptions };

  switch (outage.status.name) {
    case "Restored":
      opts.strokeColor = mapValues.restore_color;
      opts.fillColor = mapValues.restore_color;
      break;
    case "Planned":
      opts.strokeColor = mapValues.planned_color;
      opts.fillColor = mapValues.planned_color;
      break;
    case "Outage":
      opts.strokeColor = mapValues.outage_color;
      opts.fillColor = mapValues.outage_color;
      break;
    default:
    // Default case for outage
  }
  if (outage.coordinates !== null) {
    return (
      <Polygon
        options={opts}
        paths={JSON.parse(outage?.coordinates)}
        onClick={(e: React.MouseEvent) => onClick(e, outage)}
      />
    );
  } else if (
    outage.area.coordinates !== null &&
    outage.area.coordinates.length > 0
  ) {
    return (
      <Polygon
        options={opts}
        paths={outage?.area?.coordinates}
        onClick={(e: React.MouseEvent) => onClick(e, outage)}
      />
    );
  } else {
    const radius = outage.location.severity || 500;
    const center = {
      lat:
        outage.location.latitude !== 0 ? outage.location.latitude : 43.6296418,
      lng:
        outage.location.longitude !== 0
          ? outage.location.longitude
          : -80.0070466,
    };
    return (
      <Circle
        center={center}
        radius={radius}
        options={opts}
        onClick={(e: React.MouseEvent) => onClick(e, outage)}
      />
    );
  }
};

// Explicitly type the memoized component
export default memo(Outage) as React.FC<OutageProps>;
