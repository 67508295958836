import styled from "@emotion/styled";

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
`;

export const Label = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const Indicator = styled.div`
  align-items: center;
  display: flex;
  margin-left: 0.5rem;
  &:before {
    background-color: ${(props: any) => props.theme.colors[props.bg]};
    border-radius: ${(props: any) => props.theme.radii.default};
    content: "";
    display: block;
    height: 1rem;
    margin-right: 0.25rem;
    width: 1rem;
  }
`;
