import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        d="M13.875 21.5h-3.75a.5.5 0 00-.447.724l.336.671A1.989 1.989 0 0011.8 24h.4a1.99 1.99 0 001.787-1.1l.336-.671a.5.5 0 00-.448-.724zM12 4.5a1 1 0 001-1V1a1 1 0 00-2 0v2.5a1 1 0 001 1zm9.192-.692a1 1 0 00-1.414 0l-1.767 1.768a1 1 0 101.414 1.414l1.767-1.768a1 1 0 000-1.414zM23 12h-2a1 1 0 000 2h2a1 1 0 000-2zM5.99 5.576L4.222 3.808a1 1 0 00-1.414 1.414L4.576 6.99A1 1 0 005.99 5.576zM3 12H1a1 1 0 000 2h2a1 1 0 000-2zm9-6a6.5 6.5 0 00-3.715 11.827.5.5 0 01.215.411V19a1 1 0 001 1h5a1 1 0 001-1v-.763a.5.5 0 01.215-.411A6.495 6.495 0 0012 6z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export default Icon;
