import React, { useState, useCallback } from "react";
import { useField, useFormikContext } from "formik";
import { Autocomplete } from "@react-google-maps/api";

import { FormGroup, FormInput, Feedback } from "@theme/form";

import useBounds from "@hooks/useBounds";

interface AddressProps {
  label: string;
  name: string;
  type: string;
  required?: boolean;
  autocomplete?: string;
}

const Address = ({ label, ...props }: AddressProps) => {
  const [autocomplete, setAutocomplete] = useState<boolean | any>(false);
  const { isSubmitting } = useFormikContext();
  const [field, meta, helpers] = useField(props);
  const [latf, latm, latHelpers] = useField("latitude");
  const [lngf, lngm, lngHelpers] = useField("longitude");

  const onLoad = useCallback((a: any) => {
    const bounds = useBounds();
    a.setBounds(bounds);
    a.setFields(["address_component", "formatted_address", "geometry"]);
    setAutocomplete(a);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        helpers.setValue(place.formatted_address);
      }
      if (place.geometry) {
        latHelpers.setValue(place.geometry.location.lat());
        lngHelpers.setValue(place.geometry.location.lng());
      }
    }
  };

  const onFocus = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        var circle = new window.google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        autocomplete.setBounds(circle.getBounds());

        latHelpers.setValue(position.coords.latitude);
        lngHelpers.setValue(position.coords.longitude);
      });
    }
  };

  return (
    <FormGroup>
      <label htmlFor={props.name}>{label}</label>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <FormInput onFocus={onFocus} disabled={isSubmitting} />
      </Autocomplete>
      {meta.touched && meta.error ? <Feedback>{meta.error}</Feedback> : null}
    </FormGroup>
  );
};

export default Address;
