const theme = {
  colors: {
    primary: "#256C2D",
    secondary: "#196194",
    outage: "#D21E00",
    light: "#eeeeee",
    medium: "#cccccc",
  },
  shadows: {
    default: `0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)`,
    small: `0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)`,
    large: ` 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)`,
  },
  radii: {
    default: `0.375rem`,
    small: `0.25rem`,
    large: `0.5rem`,
  },
};

export default theme;
