import { useState, useCallback } from "react";
import styled from "@emotion/styled";
import { Autocomplete } from "@react-google-maps/api";
import { VisuallyHidden } from "@reach/visually-hidden";

import Button from "../Button";
import Legend from "../Legend";
import Report from "../Report";
import { Actions, Logo, Wrapper, Utility } from "./styles";

import Twitter from "@svgs/Twitter";
import { FormGroup, FormInput } from "@theme/form";
import useBounds from "@hooks/useBounds";
import logo from "@images/logo.svg";

interface HeaderProps {
  isLoaded: boolean;
  onSideToggle: Function;
  onPlaceChanged: Function;
}

const NoSpaceFormGroup = styled(FormGroup)`
  margin-bottom: 0;
`;

const Header = ({ onSideToggle, onPlaceChanged, isLoaded }: HeaderProps) => {
  const [autocomplete, setAutocomplete] = useState<boolean | any>(false);

  const onLoad = useCallback((a: any) => {
    const bounds = useBounds();
    a.setBounds(bounds);
    a.setFields(["formatted_address", "geometry"]);
    setAutocomplete(a);
  }, []);

  const handlePlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        onPlaceChanged({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      } else {
        onPlaceChanged(false);
      }
    }
  };

  const renderActions = () => (
    <Actions>
      <NoSpaceFormGroup>
        <VisuallyHidden>
          <label htmlFor="addressSearch">Search for your address</label>
        </VisuallyHidden>
        <Autocomplete onLoad={onLoad} onPlaceChanged={handlePlaceChanged}>
          <FormInput
            placeholder="Search by address"
            id="addressSearch"
            name="addressSearch"
            type="text"
          />
        </Autocomplete>
      </NoSpaceFormGroup>
      <Report />
      <Button bg="primary" onClick={() => onSideToggle()}>
        <Twitter />
        <VisuallyHidden>Twitter</VisuallyHidden>
      </Button>
    </Actions>
  );

  return (
    <Wrapper>
      <a href="https://haltonhillshydro.com/">
        <Logo src={logo} alt="Back to Website" />
      </a>
      <Utility>
        <Legend />
        {isLoaded ? renderActions() : <>Loading...</>}
      </Utility>
    </Wrapper>
  );
};

export default Header;
