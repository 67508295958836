import styled from "@emotion/styled";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { animated } from "react-spring";

export const Overlay = styled(animated(DialogOverlay))`
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1080;
`;

export const Content = styled(animated(DialogContent))`
  border-radius: ${(props: any) => props.theme.radii.default};
  box-shadow: ${(props: any) => props.theme.shadows.large};
  border: thin solid #333;
  min-width: 300px;
  z-index: 1090;
`;

export const Header = styled.header`
  align-items: flex-start;
  border-bottom: thin solid ${(props: any) => props.theme.colors.medium};
  display: flex;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  & > h2 {
    margin: 0;
  }
`;

export const Em = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
`;
