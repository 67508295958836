import React, { useState } from "react";
import styled from "@emotion/styled";
import { VisuallyHidden } from "@reach/visually-hidden";

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: ${(props: any) => props.theme.radii.default};
  box-shadow: ${(props: any) => props.theme.shadows.default};
  bottom: 3rem;
  font-size: 0.9rem;
  left: 1rem;
  padding: 1rem;
  padding-right: 2.5rem;
  position: fixed;
  max-width: 320px;
  overflow: hidden;

  p {
    margin: 0;
  }

  button {
    background: #fff;
    border-radius: ${(props: any) => props.theme.radii.default};
    border: none;
    right: 0;
    position: absolute;
    padding: 0.5rem;
    top: 0;

    &:hover {
      background-color: ${(props: any) => props.theme.colors.medium};
      cursor: pointer;
    }
  }
`;

const Disclaimer = () => {
  const [isOpen, setOpen] = useState(true);

  return isOpen ? (
    <Wrapper>
      <p>
        <strong>Disclaimer:</strong> While every effort has been made to
        accurately identify outage locations, there is a possibility that some
        locations could be incorrectly identified as being inside or outside the
        outage area.
      </p>
      <button onClick={() => setOpen(false)}>
        X<VisuallyHidden>Close</VisuallyHidden>
      </button>
    </Wrapper>
  ) : null;
};

export default Disclaimer;
