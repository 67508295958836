import { useState, useRef } from "react";
import { useTransition } from "react-spring";
import "@reach/dialog/styles.css";

import Button from "@components/Button";
import Form from "./Form";
import { Content, Overlay, Header, Em } from "./styles";

const Report = () => {
  const buttonRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const config: any = {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
  };
  const transitions = useTransition(isOpen, null, config);

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  return (
    <>
      <Button bg="outage" onClick={open}>
        Report an Outage
      </Button>
      {transitions.map(
        ({ item, key, props: styles }: any) =>
          item && (
            <div key={key}>
              <Overlay
                style={{ opacity: styles.opacity }}
                initialFocusRef={buttonRef}
                onDismiss={close}
              >
                <Content
                  aria-labelledby="reportDialogLabel"
                  style={{
                    transform: styles.y.interpolate(
                      (value: number) => `translate3d(0px, ${value}px, 0px)`
                    ),
                  }}
                >
                  <Header>
                    <h2 id="reportDialogLabel">Report an Outage</h2>
                    <Button ref={buttonRef} bg="outage" onClick={close}>
                      Close
                    </Button>
                  </Header>
                  <p>Thank you for taking the time to report a power outage.</p>
                  <p>
                    We provide service within the Town of Halton Hills. If you
                    are outside of this area, please contact your local utility.
                    You can find your local utility{" "}
                    <a
                      href="https://www.ieso.ca/learn/ontario-power-system/overview-of-sector-roles/find-your-ldc"
                      target="_blank"
                    >
                      here
                    </a>
                    .
                  </p>
                  <p>We do not reply directly to these forms.</p>
                  <Em>
                    If this is a life threatening emergency, please call 911.
                  </Em>
                  <Em>
                    If this is an electrical emergency (such as wires down),
                    please call us at 519-853-3701.
                  </Em>
                  <Form />
                </Content>
              </Overlay>
            </div>
          )
      )}
    </>
  );
};

export default Report;
