import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M17 14.75a1 1 0 00-1 1V19a1 1 0 01-1 1h-.5a.5.5 0 01-.5-.5v-5a1.5 1.5 0 00-1.5-1.5h-.926a.5.5 0 00-.477.349l-.482 1.526a1.016 1.016 0 01-.924.625H4.309a.992.992 0 01-.894-.553l-.51-1.6A.5.5 0 002.429 13H1.5A1.5 1.5 0 000 14.5v8A1.5 1.5 0 001.5 24h1.191a.992.992 0 00.894-.553.81.81 0 01.724-.447h5.382a.81.81 0 01.724.447.992.992 0 00.894.553H12.5a1.5 1.5 0 001.5-1.5.5.5 0 01.5-.5h.5a3 3 0 003-3v-3.25a1 1 0 00-1-1zm-6 5.75a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5zm6-17a3.5 3.5 0 00-2.188 6.232.5.5 0 01.188.391v.627a.75.75 0 00.75.75h2.5a.75.75 0 00.75-.75v-.627a.5.5 0 01.188-.391A3.5 3.5 0 0017 3.5zm0-1a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zm4.95-.45a.75.75 0 00-1.061 0l-.707.707a.75.75 0 001.061 1.061l.707-.707a.75.75 0 000-1.061zm1.3 4.2h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM12.757 3.818a.75.75 0 001.061-1.061l-.707-.707a.75.75 0 10-1.061 1.061zM10.75 7.75h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zm7.5 4.75h-2.5a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export default Icon;
