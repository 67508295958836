import { useEffect, useState } from "react";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import { ThemeProvider } from "@emotion/react";
import { useLoadScript } from "@react-google-maps/api";
import "@reach/skip-nav/styles.css";

import useWindowSize from "@hooks/useWindowSize";
import Disclaimer from "@components/Disclaimer";
import Header from "@components/Header";
import Footer from "@components/Footer";
import Map from "@components/Map";
import Twitter from "@components/Twitter";

import theme from "@theme/index";
import { GlobalStyles, Main } from "@theme/global-styles";

const libraries = ["places"];

const App = () => {
  const [sideIsOpen, setSideOpen] = useState(true);
  const [place, setPlace] = useState<boolean | any>(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.RAZZLE_GOOGLE_API_KEY}`,
    libraries,
  });
  const { width } = useWindowSize();

  useEffect(() => {
    if (width) {
      if (width <= 992) {
        setSideOpen(false);
      }
    }
  }, [width]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <SkipNavLink>Skip to content</SkipNavLink>
        <Header
          isLoaded={isLoaded}
          onSideToggle={() => setSideOpen(!sideIsOpen)}
          onPlaceChanged={(newPlace: Point) => setPlace(newPlace)}
        />
        <SkipNavContent />
        <Main>
          <div>
            <Map isLoaded={isLoaded} loadError={loadError} place={place} />
          </div>
          <Twitter
            isOpen={sideIsOpen}
            onToggle={(newOpen: boolean) => setSideOpen(newOpen)}
          />
          <Disclaimer />
        </Main>
        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default App;
