import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { GoogleMap } from "@react-google-maps/api";
import CircleLoader from "react-spinners/CircleLoader";

import Outage from "./Outage";
import Pan from "./Pan";
import Region from "./Region";
import Window from "./Window";

import { mapValues } from "@config";

import { Modal } from "./NotificationModal";
interface MapProps {
  isLoaded: boolean;
  loadError?: Error;
  place: Point;
}

const Map = ({ isLoaded, loadError, place }: MapProps) => {
  const theme: any = useTheme();
  const [outages, setOutages] = useState<any[]>([]);
  const [selectedOutage, setSelectedOutage] = useState<any | boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(true);
  const toggle = () => setIsShown(false);

  useEffect(() => {
    // setInterval(() => {
    //   // window.location.reload()
    //   renderMap()
    //   getOutages()
    //   renderOutages()
    // }, 10000)

    // setTimeout(() => {
    //   // window.location.reload()
    // }, 10000)
    getOutages();
    // const date = new Date
    // const todaysDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
    //   if(localStorage.getItem("todayMapOutageDate") !== todaysDate){
    //     setTimeout(() => {
    //       localStorage.setItem("todayMapOutageDate", todaysDate)
    //     }, 2000)
    //     setIsShown(true)
    //   }
    //   else {
    //     setIsShown(false)
    //   }
  }, []);

  const getOutages = () => {
    const start = new Date(getLastWeek()).getTime() / 1000;
    // const url = `${process.env.RAZZLE_OUTAGE_URL}?api_token=${process.env.RAZZLE_OUTAGE_API_KEY}`;
    const url = `${process.env.RAZZLE_OUTAGE_URL}?api_token=${process.env.RAZZLE_OUTAGE_API_KEY}&start=${start}`;
    const opts = {
      method: "GET",
    };
    fetch(url, opts)
      .then((res) => res.json())
      .then((res) => {
        const { data } = res;
        const newOutages: any = [];
        data.forEach((outage: any) => {
          if (!outageHasBeenSeen(outage, newOutages)) {
            newOutages.push(outage);
          }
        });
        setOutages(newOutages);
      })
      .catch((err) => {
        window.console.log(err);
      });
  };

  const outageHasBeenSeen = (outage: any, outages: Array<any>): boolean => {
    outages.forEach((o: any) => {
      if (outage.area.name === "General") {
        if (
          outage.location.severity == o.location.severity &&
          outage.location.latitude == o.location.latitude &&
          outage.location.longitude == o.location.longitude
        ) {
          return true;
        }
      } else {
        if (outage.area.code == o.area.code) {
          return true;
        }
      }
    });
    return false;
  };

  const getLastWeek = () => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  };

  const renderOutages = () => (
    <>
      <Region />
      {outages.map((outage: any) => (
        <Outage
          outage={outage}
          key={outage.id}
          onClick={(event: any, outage: any) => {
            setSelectedOutage({
              event,
              outage,
            });
          }}
        />
      ))}
    </>
  );

  const renderMap = () => (
    <GoogleMap
      id="outage-map"
      mapContainerStyle={{ width: "100%", height: `calc(100vh - 100px)` }}
      zoom={mapValues.zoom}
      center={
        new window.google.maps.LatLng(
          mapValues.center.lat,
          mapValues.center.lng
        )
      }
      options={{
        disableDefaultUI: true,
        gestureHandling: "cooperative",
        zoomControl: true,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_CENTER,
        },
        draggable: true,
      }}
    >
      <Pan place={place} />
      {renderOutages()}
      {selectedOutage ? (
        <Window
          outage={selectedOutage.outage}
          position={selectedOutage.event.latLng}
          onClose={() => setSelectedOutage(false)}
        />
      ) : null}

      <Modal isShown={isShown} hide={toggle} />
    </GoogleMap>
  );

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <CircleLoader
      size={200}
      color={theme.colors.secondary}
      loading={!isLoaded}
    />
  );
};

export default React.memo(Map);
