import { FunctionComponent, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import close from "../../images/icn_close.svg";

import {
  ModalDiv,
  ModalMainDiv,
  Image,
  ModalData,
  ModalTitle,
  ModalMessageContent,
} from "@theme/modal";

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
}: ModalProps) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const url = `${process.env.RAZZLE_SEND_NOTIFICATION}?api_token=${process.env.RAZZLE_OUTAGE_API_KEY}`;
    const opts: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    fetch(url, opts)
      .then((res) => res.json())
      .then((data) => setData(data?.data))
      .catch((err) => console.log(err));
  }, []);

  const modal = (
    <>
      <ModalMainDiv>
        <ModalDiv>
          <Image src={close} onClick={hide} alt="Close"></Image>
          {data.map((element: any) => (
            <ModalData key={element.id}>
              <ModalTitle>{element?.title}</ModalTitle>
              <ModalMessageContent
                dangerouslySetInnerHTML={{ __html: element?.message }}
              ></ModalMessageContent>
            </ModalData>
          ))}
        </ModalDiv>
      </ModalMainDiv>
    </>
  );

  return data?.length
    ? isShown
      ? ReactDOM.createPortal(modal, document.getElementById("root")!)
      : null
    : null;
};
