import { useSpring } from "react-spring";
import { useTheme } from "@emotion/react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

import { Wrapper, Inner } from "./styles";

interface TwitterProps {
  isOpen: boolean;
  onToggle: Function;
}

const Twitter = ({ onToggle, isOpen }: TwitterProps) => {
  const theme: any = useTheme();
  const animatedStyles = useSpring({
    opacity: isOpen ? 1 : 0,
    width: isOpen ? `350px` : `0px`,
  });

  return (
    <Wrapper style={{ width: animatedStyles.width }}>
      <Inner style={{ opacity: animatedStyles.opacity }}>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="hhhydro"
          autoHeight
          transparent
          linkColor={theme.colors.secondary}
        />
      </Inner>
    </Wrapper>
  );
};

export default Twitter;
