import { useEffect } from "react";
import { useGoogleMap } from "@react-google-maps/api";

interface PanProps {
  place: Point;
}

const Pan = ({ place }: PanProps) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (map && place) {
      map.panTo(new google.maps.LatLng(place.lat, place.lng));
    }
  }, [map, place]);

  return null;
};

export default Pan;
