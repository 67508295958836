import styled from "@emotion/styled";

// theme.ts
export const theme = {
  shadows: {
    default: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  // other theme properties...
};

export const Wrapper = styled.header`
  background-color: rgba(255, 255, 255, 0.7);
  align-items: center;
  box-shadow: ${(props: any) => props.theme.shadows.default};
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 9;
`;

export const Logo = styled.img`
  height: 80px;
  width: auto;
`;

export const Utility = styled.div`
  align-items: flex-end;
  display: flex;
  justfy-content: space-between;
  flex-direction: column;
`;

export const Actions = styled.div`
  background-color: ${(props: any) => props.theme.colors.secondary};
  border-top-left-radius: ${(props: any) => props.theme.radii.default};
  border-top-right-radius: ${(props: any) => props.theme.radii.default};
  align-items: center;
  display: flex;
  padding: 0.5rem;
  margin-bottom: -0.9rem;

  & > * {
    margin: 0 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;
