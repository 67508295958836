import React from "react";
import { useField, useFormikContext } from "formik";

import { FormGroup, FormInput, Feedback } from "@theme/form";

interface FieldProps {
  label: string;
  name: string;
  type: string;
  required?: boolean;
}

const Field = ({ label, ...props }: FieldProps) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <FormGroup>
      <label htmlFor={props.name}>{label}</label>
      <FormInput {...props} {...field} disabled={isSubmitting} />
      {meta.touched && meta.error ? <Feedback>{meta.error}</Feedback> : null}
    </FormGroup>
  );
};

export default Field;
