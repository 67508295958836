import React from "react";
import { useField, useFormikContext } from "formik";

import { FormGroup, FormSelect, Feedback } from "@theme/form";

interface OptionObject {
  value: number | string;
  label: string;
}

interface SelectProps {
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  options: Array<OptionObject>;
}

const Select = ({ label, options, placeholder, ...props }: SelectProps) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <FormGroup>
      <label htmlFor={props.name}>{label}</label>
      <FormSelect {...props} {...field} disabled={isSubmitting}>
        {placeholder ? (
          <option value={0} disabled>
            {placeholder}
          </option>
        ) : null}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </FormSelect>
      {meta.touched && meta.error ? <Feedback>{meta.error}</Feedback> : null}
    </FormGroup>
  );
};

export default Select;
