import React from "react";
import { useField, useFormikContext, Field } from "formik";
import MaskedInput from "react-text-mask";

import { FormGroup, FormInput, Feedback } from "@theme/form";

interface PhoneProps {
  name: string;
  label: string;
}

const Phone: React.FC<PhoneProps> = ({ label, ...props }) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <FormGroup>
      <label htmlFor={props.name}>{label}</label>
      <Field
        required
        disabled={isSubmitting}
        name={props.name}
        render={({ field }: { field: any }) => (
          <MaskedInput
            render={(ref, props) => <FormInput ref={ref} {...props} />}
            mask={[
              "(",
              /[1-9]/,
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            {...field}
            id={name}
            required
          />
        )}
      />
      {meta.touched && meta.error ? <Feedback>{meta.error}</Feedback> : null}
    </FormGroup>
  );
};

export default Phone;
