import React, { memo } from "react";
import { Polygon } from "@react-google-maps/api";

import { coordinates } from "@config";

// Define the Region component
const Region = () => (
  <Polygon
    paths={coordinates}
    options={{
      strokeColor: "#aaaaaa",
      strokeOpacity: 0.8,
      strokeWeight: 5,
      fillColor: "#000000",
      fillOpacity: 0,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1,
    }}
  />
);

// Explicitly type the memoized component
export default memo(Region) as React.FC;
