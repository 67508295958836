import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { hydrate } from "react-dom";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />
);

// if (module.hot) {
//   module.hot.accept();
// }
