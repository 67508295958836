import styled from "@emotion/styled";

export const Wrapper = styled.div`
  height: calc(100vh -100px);
  width: 100%;
`;

export const WindowWrapper = styled.div`
  & > p {
    margin-bottom: 0;
  }
`;

export const WindowTitle = styled.h2`
  align-items: center;
  margin-top: 0;
  display: flex;
  & > svg {
    color: ${(props: any) => props.color};
    height: auto;
    margin-right: 0.25rem;
    width: 1.5rem;
  }
`;
