export const coordinates: Array<Point> = [
  { lat: 43.524714, lng: -79.938983 },
  { lat: 43.627942, lng: -80.086604 },
  { lat: 43.734095, lng: -79.968279 },
  { lat: 43.605172, lng: -79.793049 },
  { lat: 43.543077, lng: -79.861749 },
  { lat: 43.546867, lng: -79.868308 },
  { lat: 43.543503, lng: -79.869374 },
  { lat: 43.563792, lng: -79.895438 },
];

export const mapValues = {
  outage_color: "#D21E00",
  restore_color: "#256C2D",
  planned_color: "#196194",
  speed: 300000,
  //speed: 5000,
  zoom: 12,
  center: {
    lat: 43.6296418,
    lng: -80.0070466,
  },
  earthsRadius: {
    km: 6378.1,
    mt: 6378100,
  },
};

export const polyOptions: PolyOptions = {
  strokeColor: mapValues.outage_color,
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: mapValues.outage_color,
  fillOpacity: 0.35,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 2,
};
