import React from "react";
import { useField, useFormikContext } from "formik";

interface TextAreaProps {
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
}

import { FormGroup, FormArea, Feedback } from "theme/form";

const TextArea = ({ label, ...props }: TextAreaProps) => {
	const { isSubmitting } = useFormikContext();
	const [field, meta] = useField(props);

	return (
	  <FormGroup>
		<label htmlFor={props.name}>{label}</label>
		<FormArea {...props} {...field} disabled={isSubmitting} />
		{meta.touched && meta.error ? <Feedback>{meta.error}</Feedback> : null}
	  </FormGroup>
	);
  };

  export default TextArea;
