import styled from "@emotion/styled";

export const FormRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 -1rem;
  & > div {
    flex: 1 0 50%;
    padding: 0 1rem;
  }
`;

export const FormGroup = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  & > label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  & > div {
    width: 100%;
  }
`;

export const FormInput = styled.input`
  border: thin solid ${(props: any) => props.theme.colors.medium};
  border-radius: ${(props: any) => props.theme.radii.default};
  display: block;
  padding: 0.5rem 0.75rem;
  width: 100%;

  &:focus {
    border-color: ${(props: any) => props.theme.colors.secondary};
  }
`;

export const FormSelect = FormInput.withComponent("select");
export const FormArea = FormInput.withComponent("textarea");

export const Feedback = styled.p`
  font-style: italic;
  font-size: 0.9rem;
  color: ${(props: any) => props.theme.colors.outage};
`;

export const Alert = styled.div`
  background-color: ${(props: any) =>
    props.error ? props.theme.colors.outage : props.theme.colors.primary};
  border-radius: ${(props: any) => props.theme.radii.default};
  color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;

  & > p {
    margin-top: 0;
  }

  & > p:last-child {
    margin-bottom: 0;
  }
`;
