import styled from "@emotion/styled";

export const Wrapper = styled.footer`
  align-items: center;
  background-color: ${(props: any) => props.theme.colors.secondary};
  box-shadow: ${(props: any) => props.theme.shadows.default};
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  left: 0;
  padding: 0.5rem 1rem;
  position: fixed;
  justify-content: center;
  width: 100%;
  z-index: 10;

  @media (min-width: 40rem) {
    flex-direction: row;
    justify-content: space-between;
  }

  p {
    margin: 0;
  }
  a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-deconration: underline;
    }
  }
`;
