import React from "react";
import { useFormikContext } from "formik";

import Button from "@components/Button";

const Submit = () => {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <Button
      bg="secondary"
      disabled={isSubmitting}
      type="button"
      onClick={submitForm}
    >
      {isSubmitting ? <>Submitting...</> : <>Submit</>}
    </Button>
  );
};

export default Submit;
