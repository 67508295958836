import styled from "@emotion/styled";
import { animated } from "react-spring";

export const Wrapper = styled(animated("aside"))`
  background-color: ${(props: any) => props.theme.colors.light};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  overflow: hidden;
`;

export const Inner = styled(animated("div"))`
  height: 100%;
  padding: 1rem 0;
  width: 100%;
`;
